import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchButton", "form"];

  searchButtonTargetConnected() {
    this.originalButtonHtml = this.searchButtonTarget.innerHTML;
  }

  search(e) {
    if (!this.formTarget.checkValidity()) {
      e.preventDefault();
      this.formTarget.reportValidity();
    } else {
      this.searchButtonTarget.disabled = true;
      this.searchButtonTarget.textContent =
        this.searchButtonTarget.dataset.disableLabel;
    }
  }

  resetButton() {
    this.searchButtonTarget.disabled = false;
    this.searchButtonTarget.innerHTML = this.originalButtonHtml;
  }
}
